import journeyModule from '@/services/journey';
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import moment from 'moment';
// const _ = require('lodash');

export default {
  name: 'createJourney',
  components: {
    DynamicTimelineGraphComponent
  },
  props: {
    journeyId: {
      type: String,
      required: true
    }
  },
  data() {
    // Default values for last 7 days.
    let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
    let startTime = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');

    return {
      // Jurney Timerange Stats Variables
      fetchingJourneyTimerRangeStats: true,
      journeyTimerRangeStats: null,
      // Timing Filter Variables
      journeyTimerRangeStatsDateRangeFilterValues: [startTime, endTime],
      journeyTimerRangeStatsDateRangeFilter: '10080',

      // Engagement Stats Variables
      fetchingEngagementStats: true,
      engagementStats: null,
      selectedEngagementStatsTab: 'all',
      engagementStatsChannelList: { all: 'All Channels', email: 'Email', sms: 'SMS', whatsApp: 'WhatsApp', mobilePush: 'Mobile Push', webPush: 'Web Push' },
      // Timing Filter Variables
      engagementStatsDateRangeFilterValues: [startTime, endTime],
      engagementStatsDateRangeFilter: '10080',

      // Campaign States Variables.
      fetchingCampaignStats: true,
      campaignStats: null,
      // Timing Filter Variables
      campaignStatsDateRangeFilterValues: [startTime, endTime],
      campaignStatsDateRangeFilter: '10080',

      // Popup - Customers In Journey.
      customersInJourneyPopup: {
        visible: false,
        loading: true,
        details: null
      }
    };
  },
  methods: {
    //#region ------------------ Journey Timerange Stats ------------------

    onJourneyTimerangeChange() {
      this.fetchJourneyTimerangeStats();
    },

    async fetchJourneyTimerangeStats() {
      this.fetchingJourneyTimerRangeStats = true;

      let startTime = null;
      let endTime = null;
      try {
        if (this.journeyTimerRangeStatsDateRangeFilter === '') {
          startTime = moment(this.journeyTimerRangeStatsDateRangeFilterValues[0]);
          endTime = moment(this.journeyTimerRangeStatsDateRangeFilterValues[1]);
        } else {
          startTime = moment().subtract(parseInt(this.journeyTimerRangeStatsDateRangeFilter), 'minutes');
          endTime = moment();
        }

        let params = {
          startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
        };
        let result = await journeyModule.getStatsInRange(this.journeyId, params, this);

        this.journeyTimerRangeStats = result.data;
        this.journeyTimerGraphData = {
          data: result.data.graphData,
          durationGroup: 'day',
          durationType: 'exact',
          startTime: startTime.format('YYYY-MM-DD'),
          endTime: endTime.format('YYYY-MM-DD')
        };
      } catch (err) {
        this.errorToast('Something went wrong, please contact support team.');
        console.error(err);
      } finally {
        this.fetchingJourneyTimerRangeStats = false;
      }
    },

    //#region ------------------ Engagement Stats ------------------

    onEngagementTrendTimerangeChange() {
      this.fetchEngagementTrendStats();
    },

    onEngagementChannelTabClick(index) {
      this.selectedEngagementStatsTab = index;
      this.fetchEngagementTrendStats();
    },

    async fetchEngagementTrendStats() {
      this.fetchingEngagementStats = true;

      try {
        if (this.engagementStatsDateRangeFilter === '') {
          this.startTime = moment(this.engagementStatsDateRangeFilterValues[0]);
          this.endTime = moment(this.engagementStatsDateRangeFilterValues[1]);
        } else {
          this.startTime = moment().subtract(parseInt(this.engagementStatsDateRangeFilter), 'minutes');
          this.endTime = moment();
        }

        let params = {
          channel: this.selectedEngagementStatsTab,
          startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss')
        };
        let result = await journeyModule.getJourneyEngagementStats(this.journeyId, params, this);

        this.engagementStats = result.data;
        this.engagementStatsGraphData = {
          data: result.data.graphData,
          durationGroup: 'day',
          durationType: 'exact',
          startTime: this.startTime.format('YYYY-MM-DD'),
          endTime: this.endTime.format('YYYY-MM-DD')
        };
      } catch (err) {
        this.errorToast('Something went wrong, please contact support team.');
        console.error(err);
      } finally {
        this.fetchingEngagementStats = false;
      }
    },

    //#endregion

    //#region ------------------ Campaign Stats ------------------

    onCampaignStatsTimeRangeChange() {
      this.fetchCampaignStats();
    },

    showSentMessageDialog(campaignId) {
      this.$refs.messagesSentDialog.showDialog(campaignId);
    },

    async fetchCampaignStats() {
      this.fetchingCampaignStats = true;

      let startTime = null;
      let endTime = null;
      try {
        if (this.campaignStatsDateRangeFilter === '') {
          startTime = moment(this.campaignStatsDateRangeFilterValues[0]);
          endTime = moment(this.campaignStatsDateRangeFilterValues[1]);
        } else {
          startTime = moment().subtract(parseInt(this.campaignStatsDateRangeFilter), 'minutes');
          endTime = moment();
        }
        let params = {
          startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
        };
        let result = await journeyModule.getJourneyCampaignStats(this.journeyId, params, this);
        this.campaignStats = result.data;
        this.fetchingCampaignStats = false;
      } catch (err) {
        this.fetchingCampaignStats = false;
        this.errorToast('Something went wrong, please contact support team.');
        console.error(err);
      }
    },

    //#endregion

    //#region ------------------ Start: Customers In Journey Popup ------------------

    showCustomerInJourneyDialog() {
      this.customersInJourneyPopup.loading = true;
      this.customersInJourneyPopup.visible = true;
      this.customersInJourneyPopup.details = null;

      journeyModule
        .getCustomersInJourney(this.journeyId, {}, this)
        .then((result) => {
          this.customersInJourneyPopup.details = result.data;
          this.customersInJourneyPopup.loading = false;
        })
        .catch((err) => {
          // Keep Loader On
          this.errorToast('Failed to fetch cusotmers in journey. Please contact support.');
          console.error(err);
        });
    }

    //#endregion ------------------ Start: Customers In Journey ------------------
  },

  mounted() {
    this.fetchJourneyTimerangeStats();
    this.fetchEngagementTrendStats();
    this.fetchCampaignStats();
  }
};
