<template>
  <el-drawer custom-class="journeyConditionDrawer" :visible.sync="dialogVisible" :direction="direction" :before-close="onCancel">
    <div slot="title">
      {{ title }}
    </div>

    <!-- Is Reachable On Channel -->
    <div class="w-full mt-4 px-3" v-if="conditionType == 'is_user_reachable'">
      <div class="title">Check if user is reachable on</div>
      <el-select v-model="reachableOn" placeholder="Select Channel" size="small" filterable style="width: 250px">
        <el-option v-for="(label, key) in channelList" :key="key" :label="label" :value="key"> </el-option>
      </el-select>
    </div>

    <!-- Is In Segment -->
    <div class="w-full mt-4 px-3" v-if="conditionType == 'is_in_segment'">
      <div class="title">Select Segment To Check</div>
      <el-select v-model="selectedSegment" placeholder="Select Segment" size="small" filterable style="width: 250px">
        <el-option v-for="segment in segmentList" :key="segment.id" :label="segment.name + (segment.is_deleted ? ' (Deleted)' : '')" :value="segment.id"> </el-option>
      </el-select>
    </div>

    <!-- Has Performed Event Earlier-->
    <div class="pastBehaviour" v-else-if="conditionType == 'past_behaviour'">
      <div class="px-3 mt-3">
        <UserPropertyFilterComponent class="bahaviourFilter" filterTitle="Specify Behaviour Filters" ref="behaviourFilter" v-bind:formattedPropertyFilter="behaviourFilters"></UserPropertyFilterComponent>
      </div>

      <div class="mt-3 px-4 pt-4 border-t">
        <div class="title">Behaviour Description (To show in journey)</div>
        <el-input v-model="nodeLabel" maxlength="50" show-word-limit style="width: 400px"></el-input>
        <div class="errorDiv" v-if="behaviourErrMsg">{{ behaviourErrMsg }}</div>
      </div>
    </div>

    <div class="footerButtons bg-gray-50">
      <el-button v-if="!jurneyPublished" type="success" @click="onValidateAndSave">Save Changes </el-button>
      <el-button v-else type="success" @click="publishChanges"> Publish Changes </el-button>
      <el-button type="danger" plain @click="onCancel">Cancel</el-button>
    </div>
  </el-drawer>
</template>

<script>
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import segmentModule from '@/services/sessionFilter';
const _ = require('lodash');
export default {
  props: {
    jurneyPublished: {
      type: Boolean,
      required: true
    }
  },
  components: { UserPropertyFilterComponent },
  data() {
    return {
      nodeId: null,
      title: '',
      dialogVisible: false,
      conditionType: '',
      direction: 'btt',
      nodeLabel: null,

      // Reachable variables
      reachableOn: 'email',
      channelList: {
        email: 'Email',
        mobilePush: 'Mobile Push',
        webPush: 'WebPush',
        sms: 'SMS',
        whatsApp: 'WhatsApp',
        inApp: 'In-App'
      },

      //Segment Check Variables
      segmentList: [],
      selectedSegment: null,

      // Past Behaviour Variables
      behaviourFilters: null,

      behaviourErrMsg: ''
    };
  },
  methods: {
    onValidateAndSave() {
      // No validation required, all fields have default values.
      // Except segment
      if (this.conditionType == 'is_in_segment') {
        if (!this.selectedSegment) {
          this.warningToast('Please select segment.');
          return;
        }
      } else if (this.conditionType == 'past_behaviour') {
        if (!this.nodeLabel) {
          this.behaviourErrMsg = 'Please provide behaviour criteria desccription';
          return;
        }
      }

      // Save Changes
      let data = null;
      if (this.conditionType == 'past_behaviour') {
        data = this.$refs.behaviourFilter.getFilters();
      } else if (this.conditionType == 'is_user_reachable') {
        data = this.reachableOn;
      } else if (this.conditionType == 'is_in_segment') {
        data = {
          id: this.selectedSegment,
          name: _.find(this.segmentList, (segment) => {
            return segment.id == this.selectedSegment;
          }).name
        };
      }

      this.$emit('onChange', {
        nodeId: this.nodeId,
        data: data,
        nodeLabel: this.nodeLabel
      });
      this.onCancel();
    },

    onCancel() {
      Object.assign(this.$data, this.$options.data());
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    async showDialog(nodeId, conditionType, existingData, nodeLabel) {
      console.log('existing data', existingData);
      this.nodeId = nodeId;
      this.conditionType = conditionType;
      this.nodeLabel = nodeLabel;

      // Set title based on condition type
      if (conditionType == 'past_behaviour') {
        this.title = 'Past Behaviour';
        // Set existing data
        if (existingData) {
          this.behaviourFilters = existingData;
        }
      } else if (conditionType == 'is_user_reachable') {
        this.title = 'Is User Reachable';
        // Set existing data
        if (existingData) {
          this.reachableOn = existingData;
        }
      } else if (conditionType == 'is_in_segment') {
        this.title = 'If User Belongs To Segment';
        // Set existing data
        this.selectedSegment = null;
        if (existingData) {
          this.selectedSegment = existingData.id;
        }
        await this.fetchSegmentList();
      }
      this.title = 'Condition - ' + this.title;
      this.dialogVisible = true;
    },

    async fetchSegmentList() {
      try {
        let result = await segmentModule.getSegmentList(true);
        let list = [];
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].is_deleted) {
            if (result.data[i].id == this.selectedSegment) {
              list.push(result.data[i]);
            }
          } else {
            list.push(result.data[i]);
          }
        }
        this.segmentList = list;
      } catch (err) {
        this.reportError(err);
        console.error('Failed to fetch segment list', err);
      }
    }
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.journeyConditionDrawer {
  max-width: 60%;
  height: auto !important;
  margin: auto;
  border-radius: 10px 10px 0px 0px;

  .el-drawer__header {
    height: 55px;
    background: $primary;
    padding: 5px 15px;
    color: white;
    margin: 0px;
  }

  input:disabled,
  textarea:disabled {
    background: white;
    color: #303133;
  }

  .el-drawer__body {
    width: 100% !important;
    max-height: 80vh;
    overcondition: auto;

    margin: auto;

    .errorDiv {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }

    .el-form-item__label {
      margin-bottom: 0px;
      padding-right: 20px;
    }

    .title {
      font-size: 13px;
      margin-bottom: 10px;
    }

    .pastBehaviour {
      .bahaviourFilter {
        .allUsers {
          padding-bottom: 0px !important;
        }
      }
    }

    .footerButtons {
      width: 100%;
      text-align: center;
      border-top: 1px solid #ddd;
      padding: 15px 0px 15px 0px !important;
      margin-top: 30px;

      .el-button {
        min-width: 200px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  *:focus {
    outline: 0;
  }
}
</style>
