<template>
  <el-drawer custom-class="journeyStartNodeDrawer" :visible.sync="dialogVisible" :direction="'btt'" :before-close="onCancel">
    <div slot="title">
      Journey Trigger Criteria
      <a href="https://support.growlytics.in/journeys-and-automation/journey-builder/journey-entry-points" target="_blank" class="ml-2">
        <button class="text-white background-transparent -mt-1 px-2 py-0 text-xs leading-5 outline-none focus:outline-none ease-linear transition-all duration-150 border border-white rounded-sm" type="button">Learn More <i class="el-icon-top-right"></i></button>
      </a>
    </div>

    <div class="w-full">
      <!-- Trigger Type -->
      <div class="w-full">
        <div class="w-full overflow-hidden bg-white border-gray-50">
          <ul role="list" class="divide-y divide-gray-200 mb-0">
            <li class="px-3 pt-3 pb-2">
              <div class="text-gray-900 text-sm pb-0 font-semibold">When you want to run journey?</div>
              <div class="titleRadio" size="small" @change="onTriggerTypeChange">
                <el-radio :disabled="jurneyPublished" label="entry-action" v-model="content.triggerType" size="small" border>When Customer Performs Event</el-radio>
                <el-badge value="BETA" class="badgeItem">
                  <el-radio :disabled="jurneyPublished" label="entry-segment" v-model="content.triggerType" size="small" border>Customer Enters/Exits Segment</el-radio>
                </el-badge>
                <!-- <el-badge value="BETA" class="badgeItem">
                <el-radio :disabled="jurneyPublished" label="entry-onetime" v-model="content.triggerType" size="small" border>Execute One Time</el-radio>
              </el-badge> -->
                <el-badge value="BETA" class="badgeItem">
                  <el-radio :disabled="jurneyPublished" label="entry-repeat" v-model="content.triggerType" size="small" border>Repeat At Fixed Time</el-radio>
                </el-badge>
                <!-- <el-radio label="customer-update" border>When Customer Attribute Update</el-radio> -->
              </div>
              <div class="text-secondary-600 text-semibold text-xs pt-0 pt-2">
                <span v-if="content.triggerType == 'entry-action'">Journey will be executed when customer performs an action/event.</span>
                <span v-else-if="content.triggerType == 'entry-segment'">Journey will be executed for all customers who enter/exit segment (once daily).</span>
                <span v-else-if="content.triggerType == 'entry-onetime'">Daily at specified time, journey will run for all customers who have entered/exited the segment.</span>
                <span v-else-if="content.triggerType == 'entry-repeat'">Journey will repeat daily/weekly/monthly based on repeat criteria specified. </span>
              </div>
            </li>

            <!-- On Action -->
            <li class="py-4 px-3 w-full" v-if="content.triggerType == 'entry-action'">
              <div class="text-gray-700 text-xs">Specify Event - Which Will Trigger The Journey</div>
              <div class="bg-red px-0 pt-2">
                <EventPicker ref="eventPicker" :readOnly="false" v-bind:selectedOnActionEvent="content.actionEvent" v-bind:channelType="'journey'"></EventPicker>
              </div>
            </li>

            <!-- Segment Entry/Exit -->
            <template v-else-if="content.triggerType == 'entry-segment'">
              <li class="py-4 px-3 w-full text-sm">
                <el-row>
                  <el-col :span="12">
                    <div class="pb-2 text-gray-700 text-xs">Run journey when customer</div>
                    <el-select :disabled="jurneyPublished" v-model="content.segment.entryType" size="mini" style="width: 170px">
                      <el-option value="entry" label="Enters"></el-option>
                      <el-option value="already-and-entry" label="Enters Or Already In"></el-option>
                      <el-option value="exit" label="Exits"></el-option>
                    </el-select>
                    segment
                    <el-select :disabled="jurneyPublished" v-model="content.segment.segmentId" placeholder="Select Segment" size="small" filterable style="width: 300px">
                      <el-option v-for="segment in segmentList" :key="segment.id" :label="segment.name + (segment.is_deleted ? ' (Deleted)' : '')" :value="segment.id"> </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <div class="pb-2 text-gray-700 text-xs">Check for segment entry/exists daily at</div>
                    <el-select style="width: 140px" v-model="content.segment.time" size="small" filterable>
                      <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                    </el-select>
                    &nbsp;<span class="text-gray-600 text-xs"> ({{ $store.state.token.timezoneName }} Time)</span>
                  </el-col>
                </el-row>
              </li>
              <!-- <div class="text-secondary-600 text-semibold text-xs pt-0 pt-2"> -->
              <li class="py-2 px-3 w-full text-xs text-secondary-600 text-semibold">Daily at specified time, journey will run for all customers who have entered/exited the segment.</li>
            </template>

            <!-- One-time -->
            <template v-else-if="content.triggerType == 'entry-onetime'">
              <!-- <li class="py-2 px-3 w-full text-sm">
              <span class="text-secondary-600">With one-time option, journey will run one time when you publish the journey.</span>
            </li> -->
            </template>

            <!-- Repeat -->
            <template v-else-if="content.triggerType == 'entry-repeat'">
              <li class="py-4 px-3 w-full text-sm">
                <!-- Timer Type Select -->
                <div class="text-gray-700 text-xs pb-2">How do you want to repeat journey?</div>
                <el-radio-group class="titleRadio" v-model="content.repeat.type" size="small">
                  <el-radio label="day" border>Repeat journey Daily</el-radio>
                  <el-radio label="week" border>Repeat journey Weekly</el-radio>
                  <el-radio label="month" border>Repeat journey Monthly</el-radio>
                </el-radio-group>
                <div class="text-secondary-500 text-xs pt-0 font-light">
                  <span v-if="content.repeat.type == 'day'">With daily option, journey will run every-day for given customers at given time specified by you.</span>
                  <span v-else-if="content.repeat.type == 'week'">With weekly option, journey will run every week days for given customers at given time specified by you.</span>
                  <span v-else-if="content.repeat.type == 'month'">With monthly option, journey will run on every date of month for given customers at given time specified by you.</span>
                </div>
              </li>

              <!-- Daily Option - Time Of Day For Repeat/One-Time Execution -->
              <!-- <li class="py-4 px-3 w-full text-sm" v-if="content.repeat.type == 'day'">
              <div class="text-gray-700 text-xs pb-2">Specify Time Of Day To Run Journey?</div>
              <el-select style="width: 110px" v-model="content.repeat.day.time" size="mini" filterable>
                <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
              </el-select>
              &nbsp; ({{ $store.state.token.timezoneName }} Time)
              <div class="text-secondary-700 text-xs pt-2">Journey will run at this time daily.</div>
            </li> -->
              <li class="pt-3 pb-4 px-3 w-full" v-if="content.repeat.type == 'day'">
                <div class="text-gray-600 text-xs mb-2">Journey will run daily at following time:</div>
                <div class="border border-gray-400 rounded-md py-2 px-3 bg-gray-50 text-gray-600 text-xs mt-2" v-for="(slot, index) in content.repeat.day" :key="index">
                  <span class="text-red-600 hover:cursor-pointer"> <i v-if="content.repeat.week.length > 1" @click="onRemoveDailyTimeSlot(index)" class="el-icon-delete hover:cursor-pointer"></i> &nbsp; </span>
                  Run journey daily at&nbsp;
                  <el-select style="width: 110px" v-model="slot.time" size="mini" filterable>
                    <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                  </el-select>
                  &nbsp; ({{ $store.state.token.timezoneName }} Time)
                </div>
                <el-button type="primary" size="small" style="margin-top: 15px" plain @click="onAddNewDailyTimeSlot">Add Day Slot</el-button>
              </li>

              <!-- Weekly Option -->
              <li class="pt-3 pb-4 px-3 w-full" v-if="content.repeat.type == 'week'">
                <div class="text-gray-600 text-xs mb-2">Journey will run on following week days:</div>
                <div class="border border-gray-400 rounded-md py-2 px-3 bg-gray-50 text-gray-600 text-xs mt-2" v-for="(slot, index) in content.repeat.week" :key="index">
                  <span class="text-red-600 hover:cursor-pointer"> <i v-if="content.repeat.week.length > 1" @click="onRemoveWeeklyTimeSlot(index)" class="el-icon-delete hover:cursor-pointer"></i> &nbsp; </span>
                  Run journey on&nbsp;
                  <el-select v-model="slot.day" style="width: 100px" size="mini">
                    <el-option value="0" label="Sunday"></el-option>
                    <el-option value="1" label="Monday"></el-option>
                    <el-option value="2" label="Tuesday"></el-option>
                    <el-option value="3" label="Wednesday"></el-option>
                    <el-option value="4" label="Thursday"></el-option>
                    <el-option value="5" label="Friday"></el-option>
                    <el-option value="6" label="Saturday"></el-option>
                  </el-select>
                  &nbsp;at&nbsp;
                  <el-select style="width: 110px" v-model="slot.time" size="mini" filterable>
                    <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                  </el-select>
                  &nbsp; ({{ $store.state.token.timezoneName }} Time)
                </div>
                <el-button type="primary" size="small" style="margin-top: 15px" plain @click="onAddNewWeeklyTimeSlot">Add Week Day</el-button>
              </li>

              <!-- Monthly Option -->
              <li class="pt-3 pb-4 px-3 w-full" v-if="content.repeat.type == 'month'">
                <div class="text-gray-600 text-xs mb-2">Journey will run on following days of month:</div>
                <div class="border border-gray-400 rounded-md py-2 px-3 bg-gray-50 text-gray-600 text-xs mt-2" v-for="(slot, index) in content.repeat.month" :key="index">
                  <span class="text-red-600 hover:cursor-pointer"> <i v-if="content.repeat.month.length > 1" @click="onRemoveMonthlyTimeSlot(index)" class="el-icon-delete hover:cursor-pointer"></i> &nbsp; </span>
                  Run journey on date &nbsp;
                  <el-select v-model="slot.day" style="width: 70px" size="mini">
                    <el-option v-for="index in 31" :key="index" :label="index" :value="index"></el-option>
                  </el-select>
                  &nbsp;of every month at&nbsp;
                  <el-select style="width: 110px" v-model="slot.time" size="mini" filterable>
                    <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                  </el-select>
                  &nbsp; ({{ $store.state.token.timezoneName }} Time)
                </div>
                <el-button type="primary" size="small" style="margin-top: 15px" plain @click="onAddNewMonthlyTimeSlot">Add Month Date</el-button>
              </li>
            </template>

            <!-- 10 Million User Warning -->
            <li class="py-1 px-3 w-full bg-red-600 text-white text-xs text-center" v-if="content.triggerType != 'entry-action'">If customer size for entry criteria is more than 10 million users, journey will not be executed. Make sure you are only trageting registered customers to improve your targeting.</li>
          </ul>
        </div>
      </div>

      <!-- Footer Buttons -->
      <div class="footerButtons bg-gray-50 border-t py-3">
        <el-button v-if="!jurneyPublished" type="success" @click="onValidateAndSave">Save Changes </el-button>
        <el-button v-else type="success" @click="publishChanges"> Publish Changes </el-button>
        <el-button type="danger" plain @click="onCancel">Cancel</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import EventPicker from '@/components/chartFilters/campaignEventFilter/campaignEventFilter';
import segmentModule from '@/services/sessionFilter';
import moment from 'moment';

export default {
  props: {
    jurneyPublished: {
      type: Boolean,
      required: true
    }
  },
  components: { EventPicker },
  data() {
    return {
      nodeId: '',
      dialogVisible: false,
      selectedEventInfo: null,
      content: {
        version: 1,
        triggerType: 'entry-action',
        actionEvent: null,
        segment: {
          segmentId: null,
          entryType: 'entry',
          time: 14
        },
        repeat: {
          type: 'day',
          day: [
            {
              time: 18
            }
          ],
          week: [
            {
              day: 1,
              time: 18
            }
          ],
          month: [
            {
              day: 1,
              time: 18
            }
          ]
        }
      },

      // Populators
      segmentList: null,
      timeSlotInputSchema: {
        day: '1',
        time: 17
      }
    };
  },
  methods: {
    onValidateAndSave() {
      // Validate changes first.
      if (this.content.triggerType == 'entry-action') {
        this.content.actionEvent = this.$refs.eventPicker.getFilters();
        if (this.content.actionEvent == null) {
          this.warningToast('Please specify trigger event.');
          return;
        }
      } else if (this.content.triggerType == 'entry-segment') {
        // Check segment selected.
        if (!this.content.segment.segmentId) {
          this.warningToast('Please select segment to check for entry/exit.');
          return;
        }
      } else if (this.content.triggerType == 'entry-repeat' && this.content.repeat.type != 'day') {
        // More than two executions not allowed per day.
        let repeatType = this.content.repeat.type;
        let grouping = {};
        for (let i = 0; i < this.content.repeat[repeatType].length; i++) {
          let day = this.content.repeat[repeatType][i].day.toString();
          if (!grouping[day]) grouping[day] = 0;
          grouping[day]++;
          if (grouping[day] > 2) {
            this.warningToast('Journey can repeat only twice in a day.');
            return;
          }
        }
      }

      this.$emit('onChange', {
        nodeId: this.nodeId,
        data: this.content
      });
      this.onCancel();
    },

    onCancel() {
      Object.assign(this.$data, this.$options.data());
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    showDialog(nodeId, originalContent) {
      if (originalContent && originalContent.version == 1) {
        // Don't use override json, its not working properly.
        this.content = JSON.parse(JSON.stringify(originalContent));
      } else if (originalContent) {
        this.content.actionEvent = originalContent;
      }
      this.nodeId = nodeId;
      this.dialogVisible = true;
      this.onTriggerTypeChange();
    },

    onTriggerTypeChange() {
      if (this.content.triggerType == 'entry-segment' && this.segmentList == null) {
        // Fetch segment list.
        this.fetchSegmentList();
      }
      console.log('trigger type changed ', this.content.triggerType);
    },

    async fetchSegmentList() {
      try {
        let result = await segmentModule.getSegmentList(true);
        let list = [];
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].is_deleted) {
            if (result.data[i].id == this.content.segment.segmentId) {
              list.push(result.data[i]);
            }
          } else {
            list.push(result.data[i]);
          }
        }
        this.segmentList = list;
      } catch (err) {
        this.reportError(err);
        console.error('Failed to fetch segment list', err);
      }
    },

    /*********** Methods for mangging timeslots *************/

    onAddNewDailyTimeSlot() {
      if (this.content.repeat.day.length >= 2) {
        this.warningToast('Maximum 2 times journey can repeat in a day.');
        return;
      }
      this.content.repeat.day.push({ time: 14 });
    },

    onRemoveDailyTimeSlot(index) {
      this.content.repeat.day.splice(index, 1);
    },

    onAddNewWeeklyTimeSlot() {
      // if (this.content.repeat.week.length >= 7) {
      //   this.warningToast('Maximum 7 week days can be configured.');
      //   return;
      // }
      this.content.repeat.week.push({ ...this.timeSlotInputSchema });
    },

    onRemoveWeeklyTimeSlot(index) {
      this.content.repeat.week.splice(index, 1);
    },

    onAddNewMonthlyTimeSlot() {
      // if (this.content.repeat.month.length >= 7) {
      //   this.warningToast('Maximum 20 dates can be configured.');
      //   return;
      // }
      this.content.repeat.month.push({ day: '1', time: 18 });
    },

    onRemoveMonthlyTimeSlot(index) {
      this.content.repeat.month.splice(index, 1);
    },

    getMinutesOfDay(inputDate) {
      let currentTime = moment(inputDate);
      return currentTime.format('HH:mm:ss');
    }
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.badgeItem {
  margin-top: 10px;
  .el-badge__content {
    right: 53px !important;
    font-size: 8px;
    line-height: 16px;
  }
}

.journeyStartNodeDrawer {
  width: 1200px !important;
  height: auto !important;
  margin: auto;
  border: none !important;
  // background: #f4f5f7 !important;
  border-radius: 10px 10px 0px 0px;

  .el-drawer__header {
    height: 55px;
    background: $primary;
    padding: 5px 15px;
    color: white;
    margin: 0px;
  }

  .el-drawer__body {
    padding: 0px;
    max-height: 90vh;
    overflow: auto;
    margin: 0px;

    .el-form-item__label {
      margin-bottom: 0px;
      padding-right: 20px;
    }

    .footerButtons {
      width: 100%;
      text-align: center;

      .el-button {
        min-width: 200px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  *:focus {
    outline: 0;
  }
}
</style>
