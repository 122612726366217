import JourneyDiagram from './journeyDiagram/journeyDiagram';
import journeyModule from '@/services/journey';
import OneColumnNumberComponent from '@/components/graphs/oneColumnNumber/oneColumnNumber';
import JourneyTemplates from '@/components/journeyTemplates/journeyTemplates';
import JourneyInsightsComponent from '@/pages/journeyCreate/journeyInsights/journeyInsights';
import JourneySettingsPopup from '@/components/journeySettings/journeySettings';
import moment from 'moment';
const _ = require('lodash');

export default {
  name: 'createJourney',
  components: {
    JourneyDiagram,
    OneColumnNumberComponent,
    JourneyTemplates,
    JourneySettingsPopup,
    JourneyInsightsComponent
  },
  data() {
    // Default values for last 7 days.
    let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
    let startTime = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');

    return {
      fetchingData: false,
      showSaveLoader: false,
      journeyId: null,
      journeyName: '',
      initialJourney: null,
      journeyStatus: 'draft',

      // Published Journey Variables
      selectedTab: 'overview',

      // Journey Stats Variables
      fetchingJourneyNodeStats: false,
      journeyNodeStats: null,
      // Campaign Node Stats Timing Filter
      campaignStatsDateFilterValues: [startTime, endTime],
      campaignStatsTimingFilter: '10080',

      // Journey Settings Variables
      saveType: 'draft',
      journeySettings: {
        goal_info: null,
        exit_criteria: null
      }
    };
  },
  methods: {
    backBtn() {
      this.$router.push('/journey');
    },

    async showTemplatesDialog(isOptional) {
      this.$refs.journeyTemplates.showDialog(isOptional);
    },

    async onJourneyTemplateSelected(json) {
      this.$refs.journeyDiagram.initPaper(json);
      console.log('template json final is', json);
    },

    async showSaveDialog(saveType) {
      this.saveType = saveType;
      if (this.saveType == 'draft') {
        this.$refs.journeySettingsDialog.showDialog('Journey Settings', 'Save Settings', this.journeySettings, false);
      } else if (this.saveType == 'published-settings') {
        this.$refs.journeySettingsDialog.showDialog('Journey Settings', 'Save Settings', this.journeySettings, true);
      } else if (this.saveType == 'publish') {
        this.$refs.journeySettingsDialog.showDialog('Publish Journey', 'Pubish Journey', this.journeySettings, false);
      }
    },

    async onSaveJourneySettings(journeySettings) {
      this.journeySettings = journeySettings;

      if (this.saveType == 'draft' || this.saveType == 'published-settings') {
        this.savePublishedJourneySettings();
      } else {
        this.confirmPublish();
      }
    },

    async saveDraftAtNodechange() {
      this.saveDraft();
    },

    async saveDraft() {
      try {
        this.showSaveLoader = true;

        let params = {
          data: this.$refs.journeyDiagram.getJson()
        };

        await journeyModule.updateDraft(this.journeyId, params, this);

        this.showSaveLoader = false;
        this.successToast('Draft Saved.');
      } catch (err) {
        this.showSaveLoader = false;
        console.error(err);
        this.errorToast('Something went wrong, please contact support team.');
      }
    },

    async confirmPublish() {
      // Validate first.
      let isValid = this.$refs.journeyDiagram.validate();
      if (!isValid) {
        return;
      }

      this.$swal({
        title: 'Publish Journey?',
        text: "You won't be able to make any changes to published journey. You won't be able to undo this!",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#67C23A',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Publish Journey'
      }).then(() => {
        setTimeout(() => {
          this.publish();
        }, 50);
      });
    },

    async publish() {
      try {
        this.showSaveLoader = true;

        let params = {
          id: this.journeyId,
          data: this.$refs.journeyDiagram.getJson(),
          settings: this.journeySettings,
          segment: { filters: [], performedFilter: [], notPerformedFilter: [], matchAll: true }
        };

        let result = await journeyModule.publish(params, this);
        this.journeyId = result.data;

        await this.fetchDetailsForEdit();

        this.$router.push({ name: 'UpdateJourney', params: { id: this.journeyId } });

        this.showSaveLoader = false;
        this.successToast('Journey published.');

        this.marketingTrackEvent('Journey Published', {
          'Journey Id': this.journeyId
        });
      } catch (error) {
        this.showSaveLoader = false;
        if (error && error.data) {
          this.warningToast('Journey is already published.');
          return;
        }
        console.error(error);
        this.errorToast('Something went wrong, please contact support team.');
      }
    },

    async changeStepContentOfPublishedJourney(nodeContent) {
      try {
        // Take confirmation first.
        this.showSaveLoader = true;

        // Save Step Changes
        let params = {
          campaignId: nodeContent.campaignId,
          nodeId: nodeContent.nodeId,
          nodeData: nodeContent.nodeData,
          journeyData: this.$refs.journeyDiagram.getJson()
        };
        await journeyModule.changePublishedJourneyStepContent(this.journeyId, params, this);

        this.showSaveLoader = false;
        this.successToast('Changes Published.');
      } catch (error) {
        this.showSaveLoader = false;
        console.error(error);
        this.errorToast('Something went wrong, please contact support.');
      }
    },

    async changeLinkContentOfPublishedJourney(linkContent) {
      try {
        // Take confirmation first.
        this.showSaveLoader = true;

        // Save Step Changes
        let params = {
          sourceNodeGraphId: linkContent.sourceNodeGraphId,
          targetNodeGraphId: linkContent.targetNodeGraphId,
          linkData: linkContent.linkData,
          journeyData: this.$refs.journeyDiagram.getJson()
        };
        await journeyModule.changePublishedJourneyLinkContent(this.journeyId, params, this);

        this.showSaveLoader = false;
        this.successToast('Changes Published.');
      } catch (error) {
        this.showSaveLoader = false;
        console.error(error);
        this.errorToast('Something went wrong, please contact support.');
      }
    },

    async savePublishedJourneySettings() {
      try {
        this.showSaveLoader = true;

        await journeyModule.updateJourneySettings(this.journeyId, this.journeySettings, this);

        this.showSaveLoader = false;
        this.successToast('Settings updated.');
      } catch (error) {
        this.showSaveLoader = false;
        console.error(error);
        this.errorToast('Something went wrong, please contact support.');
      }
    },

    async fetchDetailsForEdit() {
      this.fetchingData = true;
      try {
        let result = await journeyModule.getDetails(this.journeyId, this);
        if (result.data == null) {
          // Show 404
          this.$router.replace('/404');
          return;
        }

        this.journeyId = result.data.id;
        this.journeyStatus = result.data.status;

        this.journeyName = result.data.name;

        this.journeySettings = {};
        this.journeySettings.goal_info = result.data.goal_info;
        this.journeySettings.exit_criteria = result.data.exit_criteria;
        this.journeySettings.follow_capping = result.data.follow_capping;
        this.journeySettings.follow_dnd = result.data.follow_dnd;
        this.journeySettings.audience = result.data.audience;

        this.nodeList = result.data.nodeList ? result.data.nodeList : [];

        // Set diagram data.
        if (result.data.data) {
          let journeyData = JSON.parse(result.data.data);
          if (this.journeyStatus != 'draft') {
            for (let i = 0; i < journeyData.cells.length; i++) {
              let cell = journeyData.cells[i];
              cell.isPublished = true;

              let dbNodeObj = _.find(this.nodeList, function (node) {
                return cell.id == node.graph_node_id;
              });

              cell.db_id = dbNodeObj ? dbNodeObj.id : null;
              // cell.stats = dbNodeObj ? dbNodeObj.stats : null;
              cell.campaign_id = dbNodeObj ? dbNodeObj.campaign_id : null;
              cell.exitDbLinks = dbNodeObj ? dbNodeObj.nodeExits : null;
            }
          }
          this.initialJourney = journeyData;
        }

        this.fetchingData = false;
      } catch (err) {
        this.fetchingData = false;
        this.errorToast('Something went wrong, please contact support team.');
        console.error(err);
      }
    },

    //#region ----------- Start: Change Journey Name ------------

    async onChangeJourneyName() {
      let result = null;
      try {
        result = await this.$prompt('Journey Name', 'Change Journey Name', {
          confirmButtonText: 'Change Journey Name',
          cancelButtonText: 'Cancel',
          inputValue: this.journeyName,
          inputPattern: /.{3,100}/,
          inputErrorMessage: 'Campaign name must be of 3-100 characters.'
        });
      } catch (err) {
        // Ignore cancel button click;
        return;
      }

      try {
        this.showSaveLoader = true;

        await journeyModule.updateJourneyName(this.journeyId, {
          name: result.value
        });
        this.journeyName = result.value;

        this.showSaveLoader = false;
        this.successToast('Journey Name Updated.');
      } catch (err) {
        console.log('Failed to save journey name', err);
        this.errorToast('Failed to change journey name. Please contact support team.');
      }
    },

    //#region ----------- Start: Change Journey Name ------------

    //#region ----------- After publish actions ------------

    onPublishedJourneyTabChange(tab) {
      this.selectedTab = tab;
      if (tab == 'content') this.fetchJourneyNodeStats();
    },

    onShowCustomersOnJourney() {
      this.$refs.journeyInsightsComponent.showCustomerInJourneyDialog();
    },

    showCloneConfirm() {
      this.$swal({
        title: 'Copy this journey?',
        text: 'A new journey will be created with all steps, step relations and campaigns associated with steps in this the journey.',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4d7cfe',
        confirmButtonText: 'Create Copy'
      }).then(() => {
        this.cloneJourney();
      });
    },

    async cloneJourney() {
      this.showSaveLoader = true;

      try {
        let result = await journeyModule.cloneJourney(this.journeyId, this);
        this.journeyId = result.data.id;
        await this.fetchDetailsForEdit();

        this.$router.push({ name: 'UpdateJourney', params: { id: this.journeyId } });
        this.showSaveLoader = false;
        this.$swal({
          title: 'Copy Created',
          text: 'You can not start editing this new copy!',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#67C23A',
          confirmButtonText: 'Continue with new copy',
          allowEscapeKey: false,
          allowOutsideClick: false
        });
      } catch (err) {
        this.showSaveLoader = false;
        console.error(err);
        this.errorToast('Something went wrong, please contact support team.');
      }
    },

    async showDeleteConfirm() {
      this.$swal({
        title: 'Delete Journey?',
        text: "All campaigns associated with this journey will also be deleted. You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Delete Journey'
      }).then(() => {
        this.deleteJourney();
      });
    },

    async deleteJourney() {
      try {
        this.showSaveLoader = true;

        await journeyModule.deleteJourney(this.journeyId, this);

        this.$router.replace('/journey');
        this.successToast('Journey Deleted.');
      } catch (err) {
        this.showSaveLoader = false;
        console.error(err);
        this.errorToast('Something went wrong, please contact support team.');
      }
    },

    async showSuspendForm() {
      this.$swal({
        title: 'Suspend Journey?',
        text: 'If journey suspend, no new users will enter the journey. Users who have already entered the journey will continue to be in the journey until they exit.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Suspend Journey'
      }).then(() => {
        this.suspendJourney();
      });
    },

    async suspendJourney() {
      try {
        this.showSaveLoader = true;

        await journeyModule.suspendJourney(this.journeyId, this);

        this.showSaveLoader = false;
        this.$swal({
          title: 'Journey Suspended.',
          text: 'No new users will enter the journey. Users who have already entered the journey will continue to be in the journey until they exit.',
          type: 'success',
          confirmButtonColor: '#67c23a',
          confirmButtonText: 'OK'
        });
        this.journeyStatus = 'suspended';
      } catch (err) {
        this.showSaveLoader = false;
        console.error(err);
        this.errorToast('Failed to suspend journey, please contact support team.');
      }
    },

    async showEndJourneyForm() {
      this.$swal({
        title: 'Stop Journey?',
        html: '<div class="w-full text-left">If journey stopped, <strong>all customers will be removed from journey and no new customers will enter the journey</strong>. In short, this journey will stop functioning. <br /><br /> Once stopped, journey can not be resumed again. You will need to create new journey.</div>',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Stop Journey'
      }).then(() => {
        this.endJourney();
      });
    },

    async endJourney() {
      try {
        this.showSaveLoader = true;

        await journeyModule.endJourney(this.journeyId, this);

        this.showSaveLoader = false;
        this.$swal({
          title: 'Journey Ended.',
          text: 'No customers will be processed in the journey.',
          type: 'success',
          confirmButtonColor: '#67c23a',
          confirmButtonText: 'OK'
        });
        this.journeyStatus = 'suspended';
      } catch (err) {
        this.showSaveLoader = false;
        console.error(err);
        this.errorToast('Failed to stop journey, please contact support team.');
      }
    },

    //#endregion ----------- After publish actions ------------

    //#region ----------- Manage Journey Stats & Conversions ------------

    async fetchJourneyNodeStats() {
      // If already fetching details, return.
      // This happens when conteniously clicked on tab.
      if (this.fetchingJourneyNodeStats) {
        return;
      }

      this.fetchingJourneyNodeStats = true;
      try {
        if (this.campaignStatsTimingFilter === '') {
          this.startTime = moment(this.campaignStatsDateFilterValues[0]);
          this.endTime = moment(this.campaignStatsDateFilterValues[1]);
        } else {
          this.startTime = moment().subtract(parseInt(this.campaignStatsTimingFilter), 'minutes');
          this.endTime = moment();
        }

        let params = {
          startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss')
        };

        let result = await journeyModule.getNodeStats(this.journeyId, params);
        let nodeStatsData = result.data;

        // Set diagram data.
        for (let i = 0; i < this.initialJourney.cells.length; i++) {
          let cell = this.initialJourney.cells[i];
          let nodeId = cell.db_id;
          let nodeStat = nodeStatsData[nodeId];

          cell.stats = {
            entered: 0,
            exited: 0,
            waiting: 0,

            triggered: 0,
            yes: 0,
            no: 0,
            timeout: 0,
            sent: 0,
            opened: 0,
            clicked: 0,
            notOpened: 0,
            notClicked: 0,
            afterUpdate: 0,
            apiSuccess: 0,
            apiFailure: 0,

            abTestA: 0,
            abTestB: 0,
            abTestC: 0,
            abTestD: 0,
            abTestE: 0,

            exitCriteria: 0
          };

          if (nodeStat) {
            cell.stats = this.overrideJson(cell.stats, nodeStat);
          }
        }

        this.fetchingJourneyNodeStats = false;
      } catch (err) {
        this.errorToast('Something went wrong, please contact support team.');
        console.error('Failed to fetch node stats.', err);
      }
    }

    //#endregion ----------- Manage Journey Stats & Conversions ------------
  },

  beforeDestroy() {
    document.getElementById('subSideMenu').style.display = 'block';
  },

  mounted() {
    document.getElementById('subSideMenu').style.display = 'none';
    if (!this.$route.params.id) {
      this.$router.replace('/404');
      return;
    }

    // Fetch journey details.
    this.journeyId = this.$route.params.id;
    this.fetchDetailsForEdit();
  }
};
