<template>
  <el-drawer custom-class="updateUserAttributeDrawer" :visible.sync="dialogVisible" :direction="direction" :before-close="onCancel">
    <div slot="title">
      Update User Attribute
      <a href="https://support.growlytics.in/journeys-and-automation/journey-builder/updating-customer-attributes-in-journey" target="_blank" class="ml-2">
        <button class="text-white background-transparent -mt-1 px-2 py-0 text-xs leading-5 outline-none focus:outline-none ease-linear transition-all duration-150 border border-white rounded-sm" type="button">Learn More <i class="el-icon-top-right"></i></button>
      </a>
    </div>

    <div v-if="fetchingData" class="h-56" v-loading="true"></div>

    <el-form :model="data" ref="couponForm" :rules="formRules" v-else>
      <!-- SElect Attribute To Update -->
      <el-form-item label="Select Attribute To Update" prop="attribute">
        <el-select v-model="data.attribute" filterable @change="onSelectedAttributeChange">
          <el-option v-for="(prop, index) in userPropertyList" :value="prop.columnName" :label="prop.name + ` ( Type - ${prop.typeDisplayText.toUpperCase()} )`" :key="index">
            <span style="float: left">{{ prop.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px"> {{ prop.typeDisplayText }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <!-- Update Value-->
      <el-row :gutter="15" v-if="selectedAttribute">
        <el-col :span="8">
          <el-form-item label="How you want to update attribute?" prop="attribute">
            <el-select v-model="data.updateAction" filterable>
              <el-option v-for="(prop, index) in updateOptions[selectedAttribute.typeDisplayText]" :value="prop.value" :label="prop.label" :key="index"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <!-- Text -->
          <template v-if="selectedAttribute.typeDisplayText == 'Text'">
            <el-form-item label="Specify Value" prop="attribute">
              <div class="dd">
                <el-input type="text" v-model="data.updateValue" maxlength="500" show-word-limit></el-input>
              </div>
            </el-form-item>
            <div class="text-xs text-gray-400" style="position: absolute; top: 8px; right: 10px">If empty, null value will be set.</div>
          </template>

          <!-- Number -->
          <template v-else-if="selectedAttribute.typeDisplayText == 'Number'">
            <el-form-item label="Specify Value" prop="attribute">
              <div class="dd">
                <el-input type="number" v-model="data.updateValue"></el-input>
              </div>
            </el-form-item>
          </template>

          <!-- Boolean -->
          <template v-else-if="selectedAttribute.typeDisplayText == 'Yes/No'">
            <el-form-item label="Specify Value" prop="attribute">
              <div class="dd">
                <el-select v-model="data.updateValue">
                  <el-option :value="true" label="True"></el-option>
                  <el-option :value="false" label="False"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </template>

          <!-- Date -->
          <template v-else-if="selectedAttribute.typeDisplayText == 'Date-Time'">
            <template v-if="data.updateAction == 'set'">
              <el-form-item label="Specify Value" prop="attribute">
                <div class="dd">
                  <el-date-picker v-model="data.updateValue" type="datetime" placeholder="Select date and time"> </el-date-picker>
                </div>
              </el-form-item>
            </template>
            <div class="pt-9 text-sm text-gray-400" v-else-if="data.updateAction == 'set-current-time'">Will be update to the journey time (When user will be processed in journey).</div>
            <!-- Date Increase Decrease -->
            <template v-else>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="Specify Value" prop="attribute">
                    <div class="dd">
                      <el-input type="number" v-model="data.updateValue"></el-input>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Duration Type" prop="attribute">
                    <div class="dd">
                      <el-select v-model="data.durationUnit">
                        <el-option label="Minutes" value="minutes"></el-option>
                        <el-option label="Hours" value="hours"></el-option>
                        <el-option label="Days" value="days"></el-option>
                        <el-option label="Months" value="months"></el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </template>
        </el-col>
      </el-row>

      <div class="footerButtons">
        <el-button v-if="!jurneyPublished" type="success" @click="onValidateAndSave"> Continue </el-button>
        <el-button v-else type="success" @click="publishChanges"> Publish Changes </el-button>
        <el-button type="danger" plain @click="onCancel">Cancel</el-button>
      </div>
    </el-form>
  </el-drawer>
</template>

<style lang="scss" src="./journeyUpdateUserNode.scss"></style>

<script>
import customerServices from '../../../services/customer';
const _ = require('lodash');

export default {
  props: {
    jurneyPublished: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fetchingData: true,

      nodeId: null,
      dialogVisible: false,
      direction: 'btt',

      userPropertyList: [],
      data: {
        updateAction: 'set',
        attribute: null,
        updateValue: null,
        durationUnit: 'hours'
      },
      formRules: {},
      selectedAttribute: null,

      updateOptions: {
        Text: [{ value: 'set', label: 'Set Value To' }],
        Number: [
          { value: 'set', label: 'Set Value To' },
          { value: 'increase', label: 'Increase Value By' },
          { value: 'decrease', label: 'Decrease Value By' }
        ],
        'Yes/No': [{ value: 'set', label: 'Set Value To' }],
        'Date-Time': [
          { value: 'set', label: 'Set Time To' },
          { value: 'set-current-time', label: 'Set Time To Current Time' },
          { value: 'increase', label: 'Increase Existing Time By' },
          { value: 'decrease', label: 'Decrease Existing Time By' }
        ]
      }
    };
  },
  methods: {
    onValidateAndSave() {
      let selectedProperty = _.find(this.userPropertyList, (prop) => {
        return prop.columnName == this.data.attribute;
      });

      this.$emit('onChange', {
        nodeId: this.nodeId,
        data: {
          campaignName: 'Update - ' + selectedProperty.name,
          data: this.data
        }
      });
      this.onCancel();
    },

    onCancel() {
      Object.assign(this.$data, this.$options.data());
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    async showDialog(nodeId, flowType, existingData) {
      this.fetchUserPropertyList().then(() => {
        this.nodeId = nodeId;
        if (existingData) {
          this.data = existingData.data;

          this.selectedAttribute = _.find(this.userPropertyList, (prop) => {
            return prop.columnName == this.data.attribute;
          });
        }

        this.dialogVisible = true;
      });
    },

    /*********** Methods for mangging form data *************/

    async fetchUserPropertyList() {
      try {
        this.fetchingData = true;
        let result = await customerServices.fetchUserPropertyList(null, this);
        if (result.data.success) {
          this.userPropertyList = result.data.data;
        }
      } catch (error) {
        this.reportError(error);
      }

      this.fetchingData = false;
    },

    onSelectedAttributeChange() {
      this.selectedAttribute = _.find(this.userPropertyList, (prop) => {
        return prop.columnName == this.data.attribute;
      });
      this.data.updateAction = 'set';
      this.data.updateValue = null;
    }
  }
};
</script>
