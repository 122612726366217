<template>
  <el-drawer custom-class="journeySettingsDialog" :visible.sync="visible" direction="rtl" size="1100px" :title="title" :show-close="false">
    <el-row slot="title" class="footerSave">
      <el-col :span="12" style="line-height: 30px">{{ title }} </el-col>
      <el-col :span="12">
        <div class="text-right">
          <el-button size="small" type="success" @click="onDialogSaveClick">{{ buttonText }}</el-button>
          <el-button size="small" type="danger" @click="visible = false">Cancel</el-button>
        </div>
      </el-col>
    </el-row>
    <div class="journeySettingsContent">
      <div class="mt-2 mb-3 border rounded overflow-hidden relative bg-white" shadow="never">
        <div class="clearfix w-full py-2 px-2 bg-gray-100 border-b">
          <span class="text-sm">Basic Journey Settings</span>
        </div>

        <div class="p-0">
          <el-form label-position="top" class="journeyNameForm" ref="saveJourneyForm" :model="journeyForm" :rules="journeyFormRules">
            <!-- DND Settings -->
            <div class="relative pt-4 px-4">
              <a href="https://support.growlytics.in/account-settings/campaign-settings/setting-up-frequency-capping" target="_blank" class="absolute right-6">
                <button class="text-secondary-800 background-transparent uppercase font-bold py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Learn More About Frequency Capping <i class="el-icon-top-right"></i></button>
              </a>
              <el-checkbox v-model="journeyForm.follow_capping" class="font-medium text-gray-700"> Follow Frequency Capping </el-checkbox>
              <div class="text-gray-400 text-xs pl-4 -mt-1">
                If set, message will not be sent if frequency capping limit reached for that customer.
                <a target="_blank" href="/settings/campaign-settings" class="text-primary-600">Click here</a>
                to check your frequency capping limits.
              </div>
            </div>
          </el-form>
        </div>

        <el-divider></el-divider>

        <div class="-mt-2 -mb-1 px-4 pb-3 bg-white relative">
          <div class="text-secondary-800 absolute top-2 right-6 text-xs font-bold">If used, customers with matching the filters only will enter journey.</div>
          <el-checkbox v-model="useAudienceFilter" class="font-medium text-gray-700"> Add Customer Filters </el-checkbox> &nbsp;
          <div class="inline mt-10 items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-500 text-white" style="font-size: 9px; margin-top: -10px">BETA</div>

          <div v-if="useAudienceFilter" class="px-2 pb-1 bg-gray-50 border rounded">
            <UserPropertyFilterComponent v-bind:filterTitle="'Journey Customer Filters'" ref="userFilter" v-bind:formattedPropertyFilter="journeyForm.audience"></UserPropertyFilterComponent>
          </div>
        </div>
      </div>

      <!-- Journey Goals Tracking -->
      <CampaignGoalsPopup ref="goalsPopup" title="Journey"></CampaignGoalsPopup>

      <!-- Exit Criteria Dialog -->
      <JourneyExitCriteriaPopup ref="journeyExitCriteriaDialog"></JourneyExitCriteriaPopup>
    </div>
  </el-drawer>
</template>

<style lang="scss" src="./journeySettings.scss"></style>

<script>
import journeySettingsComponent from './journeySettingsComponent';
export default journeySettingsComponent;
</script>
